<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M17.8912 7.54375C16.1965 4.23719 12.8415 2 8.99998 2C5.15842 2 1.80248 4.23875 0.108733 7.54406C0.037246 7.68547 0 7.8417 0 8.00016C0 8.15861 0.037246 8.31484 0.108733 8.45625C1.80342 11.7628 5.15842 14 8.99998 14C12.8415 14 16.1975 11.7612 17.8912 8.45594C17.9627 8.31453 18 8.1583 18 7.99984C18 7.84139 17.9627 7.68516 17.8912 7.54375ZM8.99998 12.5C8.10997 12.5 7.23994 12.2361 6.49992 11.7416C5.75989 11.2471 5.18312 10.5443 4.84252 9.72208C4.50193 8.89981 4.41282 7.99501 4.58645 7.12209C4.76008 6.24918 5.18867 5.44736 5.818 4.81802C6.44734 4.18868 7.24916 3.7601 8.12208 3.58647C8.99499 3.41283 9.89979 3.50195 10.7221 3.84254C11.5443 4.18314 12.2471 4.75991 12.7416 5.49993C13.2361 6.23995 13.5 7.10998 13.5 8C13.5003 8.59103 13.3841 9.17632 13.158 9.72242C12.932 10.2685 12.6005 10.7647 12.1826 11.1826C11.7647 11.6005 11.2685 11.932 10.7224 12.158C10.1763 12.3841 9.59101 12.5003 8.99998 12.5ZM8.99998 5C8.73221 5.00374 8.46617 5.04358 8.20904 5.11844C8.42099 5.40646 8.52269 5.7609 8.49572 6.11748C8.46875 6.47406 8.31487 6.80917 8.06201 7.06203C7.80915 7.31489 7.47405 7.46876 7.11746 7.49574C6.76088 7.52271 6.40644 7.42101 6.11842 7.20906C5.95441 7.81331 5.98402 8.45377 6.20307 9.04031C6.42213 9.62685 6.8196 10.1299 7.33955 10.4787C7.8595 10.8275 8.47574 11.0045 9.10153 10.9847C9.72733 10.965 10.3312 10.7495 10.8281 10.3685C11.325 9.9876 11.6899 9.46044 11.8715 8.86125C12.0531 8.26205 12.0422 7.62099 11.8404 7.0283C11.6386 6.43561 11.256 5.92114 10.7464 5.55728C10.2369 5.19343 9.62609 4.99853 8.99998 5Z"
        fill="#EE8B7B"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Eye',
}
</script>

<style>

</style>
