<template>
  <b-form-tags
    :id="`input-${name}`"
    :value="value"
    :name="name"
    no-outer-focus
    class="custom-form-tags mb-2 h9"
    :limit="limit"
    :required="required"
    @input="input"
    v-on="listeners"
  >
    <template #default="{ tags, disabled, addTag, removeTag }">
      <ul
        v-if="tags.length > 0"
        class="list-inline d-inline-block mb-2"
      >
        <li
          v-for="tag in tags"
          :key="tag"
          class="list-inline-item"
        >
          <b-form-tag
            :title="tag"
            :disabled="disabled"
            variant="primary"
            @remove="removeTag(tag)"
          >
            {{ mapTag(tag) }}
          </b-form-tag>
        </li>
      </ul>

      <b-dropdown
        size="sm"
        variant="forms"
        block
        menu-class="w-100"
      >
        <template #button-content>
          {{ buttonText }}
        </template>
        <b-dropdown-form @submit.stop.prevent="() => {}">
          <b-form-group
            :label="searchLabel"
            label-for="tag-search-input"
            label-cols-md="auto"
            class="mb-0"
            label-size="sm"
            :disabled="disabled"
          >
            <b-form-input
              id="tag-search-input"
              ref="search"
              v-model="search"
              :placeholder="placeholder"
              type="search"
              size="sm"
              autocomplete="off"
            />
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-divider />
        <b-dropdown-item-button
          v-for="option in availableOptions"
          :key="option.value"
          @click="onOptionClick({ option, addTag })"
        >
          <slot
            name="item"
            :option="option"
          >
            {{ option.text }}
          </slot>
        </b-dropdown-item-button>
        <b-dropdown-text v-if="availableOptions.length === 0">
          There are no options available to select
        </b-dropdown-text>
      </b-dropdown>
    </template>
  </b-form-tags>
</template>

<script>
export default {
  name: 'FormTags',
  props: {
    name: {
      type: String,
      requried: false,
      default: '',
    },
    searchLabel: {
      type: String,
      requried: false,
      default: '',
    },
    limit: {
      type: Number,
      required: false,
      default: 999999,
    },
    buttonText: {
      type: String,
      requried: false,
      default: 'Select options',
    },
    required: {
      type: Boolean,
      requried: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search',
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        // Check if value is array
        if (!Array.isArray(value)) {
          console.warn('Options must be array')
          return false
        }
        // In case options has values, it must be array of objects with text and value
        if (value.length) {
          if (value[0].value === undefined && value[0].text === undefined) {
            console.warn('Options must be array of objects with "text" and "value" keys')
            return false
          }
        }
        return true
      },
    },
  },
  data () {
    return {
      search: '',
    }
  },
  computed: {
    criteria () {
      return this.search.trim().toLowerCase()
    },
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    availableOptions () {
      // Exclude already selected ones from options
      let options = []
      if (typeof this.options[0] === 'object') {
        options = this.options.filter(o => {
          return this.value.indexOf(o.id) === -1
        })
      } else {
        this.options.filter(o => this.value.indexOf(o) === -1)
      }

      // Check if search string exists, if exists search by that
      if (this.criteria) {
        return options.filter(o => o.text.toLowerCase().indexOf(this.criteria) > -1)
      }

      return options
    },
  },
  methods: {
    input (value) {
      this.$emit('input', value)
    },
    mapTag (tag) {
      for (let i = 0; i < this.options.length; i++) {
        const option = this.options[i]
        if (option.value === tag) {
          return option.text
        }
      }
    },
    onOptionClick ({ option, addTag }) {
      addTag(option.value)
      this.search = ''
    },
  },
}
</script>

<style lang="scss" scoped>
// Add some spacing between tags
  .badge.badge-primary {
    margin-bottom: 4px;
  }
</style>
